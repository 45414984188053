<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Отчет об успеваемости и качеству знаний учащихся 1-11 классов
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        v-model="filter.educationYear"
                        :options="educationYears"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      Четверть
                    </template>
                    <b-form-select
                        v-model="filter.quarter"
                        :options="quarterOptions"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      Преподаватель
                    </template>
                    <b-form-select
                        v-model="filter.teacherId"
                        :options="teacherOptions"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      Предмет
                    </template>
                    <b-form-select
                        v-model="filter.subjectId"
                        :options="subjectOptions"
                    ></b-form-select>
                  </b-form-group>
                </div>  
              </div>
              <b-button type="button" @click="reloadData" variant="primary">{{
                  $t("common.filter")
                }}
              </b-button>

              <b-button type="button" @click="exportData" variant="primary" style="margin-left: 10px">Экспорт</b-button>
            </b-form>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th rowspan="2">Класс</th>
                <th rowspan="2">Пердмет</th>
                <th rowspan="2">Кол-во уч-ся на конец четверти</th>
                <th rowspan="2">Аттестовано</th>
                <th colspan="4">Не аттестовано</th>
                <th colspan="4">Успевают</th>
                <th rowspan="2">% качества</th>
                <th rowspan="2">% успеваемости</th>
                <th rowspan="2">% СОУ</th>
              </tr>
              <tr>
                <th>Всего</th>
                <th>1-2 кл.</th>
                <th>10-11 кл.</th>
                <th>Другие причины</th>
                <th>«5»</th>
                <th>«4»</th>
                <th>«3»</th>
                <th>«2»</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, k) in data" :key="k">                
                <td>{{ item.unitName }}</td>
                <td>{{ item.subjectName }}</td>
                <td>{{ item.periodEndStudentsCount }}</td>
                <td>{{ item.certified }}</td>
                <td>{{ item.unCertifiedAll }}</td>
                <td>{{ item.unCertified12 }}</td>
                <td>{{ item.unCertified1011 }}</td>
                <td>{{ item.unCertifiedOther }}</td>
                <td>{{ item.certified5 }}</td>
                <td>{{ item.certified4 }}</td>
                <td>{{ item.certified3 }}</td>
                <td>{{ item.certified2 }}</td>
                <td>{{ item.quality.toFixed(2) }}</td>
                <td>{{ item.progress.toFixed(2) }}</td>
                <td>{{ item.sou.toFixed(2) }}</td>
              </tr>
              </tbody>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import DictionariesService from "@/common/dictionaries.service";
import ApiService from "@/common/api.service";
export default {
  name: "EducationProgressReportByTeacher",
  components: {
    KTPortlet
  },
  data() {
    return {
      filter:{
        quarter: 1,
        educationYear: DictionariesService.currentYear()
      },
      data:[],
      quarterOptions:DictionariesService.quarterOptions(),
      educationYears: DictionariesService.educationYearOptions(),
      subjectOptions:[],
      teacherOptions:[],
    };
  },
  mounted() {
    ApiService.querySecured("dictionaries/subjects", {}).then(({data}) => {
      this.subjectOptions = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });

    ApiService.querySecured("dictionaries/teachersByGroup", {}).then(({data}) => {
      this.teacherOptions = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
    
  },
  methods: {
    reloadData(){
      let $this = this;
      if(this.filter.subjectId != null){
        this.filter.subjectIds = [];
        this.filter.subjectIds.push(this.filter.subjectId);
      }
   
      this.filter.teacherIds = [];
      this.filter.teacherIds.push(this.filter.teacherId);
      this.filter['export'] = false;
      ApiService.postSecured("schoolReports/eduProgressReportByTeacher", this.filter).then(({data}) => {
        $this.data = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    exportData(){
      if(this.filter.subjectId != null){
        this.filter.subjectIds = [];
        this.filter.subjectIds.push(this.filter.subjectId);
      }

      this.filter.teacherIds = [];
      this.filter.teacherIds.push(this.filter.teacherId);
      let filters = this.filter;
      filters['export'] = true;
      ApiService.downloadSecured("schoolReports/eduProgressReportByTeacher", filters)
          .then(({data}) => {
            const url = URL.createObjectURL(new Blob([data], {
              type: 'application/vnd.ms-excel'
            }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'Отчет.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    }
  }
};
</script>
